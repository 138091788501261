import './RhythmMeter.css';

import React from 'react';

import * as Consts from './RhythmConsts.js';
import { RhythmContext } from './RhythmContext.js';

export default function RhythmScorer() {
    const { conductorRef } = React.useContext(RhythmContext);
    const [score, setScore] = React.useState(0);
    const [increment, setIncrement] = React.useState();
    
    const onScoreChange = React.useCallback((score, perfects) => {
	setIncrement(score);
	setScore(perfects);
    }, []);
    
    React.useEffect(() => {
	if (!conductorRef.current) {
	    return;
	}
	conductorRef.current.subscribeScoreChange(onScoreChange, 'scorer');
    }, [conductorRef.current]);

    const text = increment > 0 ? '+' + increment : '';
    return (
	<div key={score + '_' + increment} className={"melting-text-container"}>
	    <h1 data-text={text} className="melting-text">{text}</h1>
	</div>
    );
}

