import React from 'react';

import * as Consts from './RhythmConsts.js';
import { RhythmContext } from './RhythmContext.js';
import { useConductedAnimationFrame, useUniqueID } from './RhythmHook.js';

export default function RhythmLyrics({}) {
    const componentId = useUniqueID();//React.useId();
    const { conductorRef } = React.useContext(RhythmContext);
    const [lyrics, setLyrics] = React.useState('');

    useConductedAnimationFrame((cb, pb) => {
	let conductor = conductorRef.current;
	
	if (!conductor) {
	    return;
	}

	let currLyrics = conductor.currentSong.lyrics;
	let bar = Math.floor(cb / Consts.BAR);
	let line = bar < currLyrics.length ? currLyrics[bar] : '';
	setLyrics(line);
    }, componentId, 0);
    
    return (
	<div className="songLyrics">
	    <h1 className="lyrics">{lyrics}</h1>
	</div>
    );
}
