import './RhythmGame.css';

import React from 'react';

import { RhythmBeat } from './RhythmNotes.js';
import { useConductedAnimationFrame, useUniqueID } from './RhythmHook.js';
import { useWebSocket } from './RhythmSocket.js';
import * as Consts from './RhythmConsts.js';
import { RhythmContext } from './RhythmContext.js';
import { RhythmFeedback, RhythmInfo } from './RhythmFeedback.js';
import RhythmLyrics from './RhythmLyrics.js';
import RhythmGenerator from './RhythmGenerator.js';
import RhythmMeter from './RhythmMeter.js';
import RhythmScorer from './RhythmScorer.js';
import RhythmQR from './RhythmQR.js';

import { RhythmBackground, RhythmFlash } from './RhythmBackground.js';
import {
    s,
    s2,
    GiantPeach,
    MoreThanWords,
    Illusions,
} from './Songs.js';

function RhythmOrbit() {
    const componentId = useUniqueID();// React.useId();
    const [beat, setBeat] = React.useState(0);
    useConductedAnimationFrame((cb, pb) => {
	setBeat(prevBeat => (cb % Consts.BAR)); // / Consts.BAR);
    }, componentId);
    
    return <RhythmBeat beat={beat} />;
}

function RhythmRing({beat, show, clue, radius=Consts.ORBIT_R}) {
    const componentId = useUniqueID();// React.useId();
    const [pulse, setPulse] = React.useState(0);
    const [hide, setHide] = React.useState(false);
    
    useConductedAnimationFrame((cb, pb) => {
	setPulse(prevPulse => (prevPulse + 1) % 2);
	// setHide(true);
    }, componentId, beat);

    // Show the beat after hiding it above
    useConductedAnimationFrame((cb, pb) => {
	// setHide(false);
    }, componentId + '_show', (beat + Consts.NOTE_4) % Consts.BAR);

    
    let a = beat * 2 * Math.PI / Consts.BAR;
    let x = radius * Math.sin(a);
    let y = -radius * Math.cos(a);

    const style = {
	"--beat-pos-x": x.toFixed(4) + 'px',
	"--beat-pos-y": y.toFixed(4) + 'px',
    };

    let classes = [
	'circle',
	show && !hide ? 'show' : 'hide',
    ].join(' ');

    return (
    	<div className={classes} style={style}>
	    <span className={'pulse' + pulse} />
	    <b className="rhythmClue">
		{clue}
	    </b>
	</div>
    );
}

function RhythmSong() {
    const componentId = useUniqueID();//React.useId();
    const { conductorRef } = React.useContext(RhythmContext);

    const [song, setSong] = React.useState(null);
    const [bar, setBar] = React.useState(0);
    const [beat, setBeat] = React.useState(0);
    
    const onSongChange = React.useCallback((newSong) => {
	setSong(newSong);
    }, []);

    // This hook will only run once each when the conductor is null and when
    // the reference is set. Don't except anything else when the internal
    // guts of the conductor changes.
    React.useEffect(() => {
	if (!conductorRef.current) {
	    return;
	}
	setSong(conductorRef.current.currentSong);
	conductorRef.current.subscribeSongChange(onSongChange, 'song');
    }, [conductorRef.current]);

    useConductedAnimationFrame((cb, pb, crossBeat) => {
	let bar = Math.floor(cb / Consts.BAR);
	let beat = cb % Consts.BAR;
	setBar(bar);
	setBeat(beat);
    }, componentId, true);

    
    const renderRhythmClue = () => {
	if (!song) {
	    return null;
	}
	return (
	    <b className={"songClue " + song.getClueClass()}>
		{song.getClue()}
	    </b>
	);
    };
    
    return (
	<>
            {Array.from(
                Array(Consts.BAR / Consts.NOTE_8), (e, i) => {
                    let v = i * Consts.NOTE_8;
		    let b = bar;
		    if (beat > v) {
			// show the beat on the next bar since we've passed it.
			b += 1;
		    }
                    return (
			<RhythmRing
			    key={"beat-" + i}
			    show={song ? song.shouldShow(b, v) : false}
			    clue={song ? song.getBeatClue(b, v) : null}
			    beat={v}
			/>
		    );
                },
            )}
	    {renderRhythmClue()}
	</>
    );
}

export default function RhythmGame() {
    const componentId = useUniqueID();//React.useId();
    const { conductorRef } = React.useContext(RhythmContext);
    
    const [flash, setFlash] = React.useState(false);
    const [band, setBand] = React.useState('flatdog');
    const [name, setName] = React.useState('name');
    const [song, setSong] = React.useState(null);
    const [started, setStarted] = React.useState(false);
    
    const [connected, setConnected] = React.useState(false);
    
    const userBeat = React.useCallback((time) => {
	conductorRef.current.beat();
	setFlash(true);
	setTimeout(() => setFlash(false), 150); // Remove flash class right after
    }, []);

    // This hook will only run once each when the conductor is null and when
    // the reference is set. Don't except anything else when the internal
    // guts of the conductor changes.
    React.useEffect(() => {
	if (!conductorRef.current) {
	    return;
	}
	onSongChange(conductorRef.current.currentSong);
	conductorRef.current.subscribeSongChange(onSongChange, 'info');
    }, [conductorRef.current]);

    
    const openCallback = (e) => {	
	setConnected(true);
    };
    
    const messageCallback = (e) => {
	let conductor = conductorRef.current;
        const data = JSON.parse(e.data);
	
        switch (data.type) {
        case 'graded':
	    if ('score' in data) {
		conductor.onlineScore(data['score']);
	    }
            break;
	default:
	    break;
	}
    };
    
    const closeCallback = (e) => {
	setConnected(false);
    };
    
    const sendMessage = useWebSocket(
	openCallback,
	messageCallback,
	closeCallback,
    );

    const sendUpdate = React.useCallback(() => {
	if (!connected) {
	    return;
	}
	
	let payload = conductorRef.current.getUpdatePayload();
	payload.type = 'update';
	payload.band = band;
	payload.started = started;
	sendMessage(payload);
    }, [connected, band, started]);

    React.useEffect(() => {
	sendUpdate();
    }, [band, name, song, started]);
    
    const onSongChange = React.useCallback((newSong) => {
	setName(newSong.getName());
	setSong(newSong);
    }, []);
    
    const handleKeyDown = React.useCallback((event) => {
	if (!conductorRef.current) {
	    return;
	}
	
	// console.log('pressed:' + event.key + '!');
	switch (event.key) {
	case 'q':
	    conductorRef.current.loadSong(Illusions.resetState());
	    
	    return;
	//case 'q':
	    //return conductorRef.current.loadSong(RhythmGenerator.transition());
	case 'w':
	    // Generates a random stage
	    conductorRef.current.loadSong(RhythmGenerator.genSimple1());
	    return;
	case 'e':
	    // Generates a random stage
	    conductorRef.current.loadSong(RhythmGenerator.genSimple2());
	    return;
	case 'r':
	    // Generates a random stage
	    conductorRef.current.loadSong(RhythmGenerator.genClockPuzzle());
	    return;
	case 't':
	    conductorRef.current.loadSong(RhythmGenerator.genLetterPuzzle());
	    return;
	case ',':
	    // testing increase bpm
	    conductorRef.current.setBPM(conductorRef.current.bpm + 5);
	    return;
	case '.':
	    // testing decreasing bpm
	    conductorRef.current.setBPM(conductorRef.current.bpm - 5);	    
	    return;
	    
	case 'l':
	    conductorRef.current.loadSong(MoreThanWords.resetState());
	    return;
	case 'k':
	    conductorRef.current.loadSong(GiantPeach.resetState());
	    return;
	    
	case 's':
	    conductorRef.current.start();
	    setStarted(true);
	    //if (t0) {
	    //sendMessage({
	    //type: 'start',
	    //start_time: t0,
	    //});
	    //}
	    return;
	    
	case 'p':
	    conductorRef.current.stop();
	    setStarted(false);
	    return;
	case ' ':
	    userBeat(event.timeStamp);
	    return;
	case 'c':
	    setBand('tuan');
	    return;
	case 'v':
	    setBand('flatdog');
	    return;
	case 'b':
	    setBand('palefire');
	    return;
	default:
	    return;
	}
    }, []);
    
    // Register keylistener
    React.useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            // unsubscribe event
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleKeyDown]);

    useConductedAnimationFrame((cb, pb) => {
	let data = conductorRef.current.getGradableData()
	data.type = 'grade';
	// sendMessage(data);
	sendMessage({
	    type: 'calibrate',
	    time: Date.now(),
	});
    }, componentId, Consts.BAR - Consts.NOTE_16);

    
    return (
	<div className="gameLayout">
	    <RhythmMeter />
	    <div className="gameScreen">
		{/*<RhythmQR />*/}
		<RhythmBackground name={band} flash={flash} />
		<RhythmFlash flash={flash} />
		<RhythmFeedback />
		<RhythmScorer />
		<RhythmSong />
		<RhythmOrbit />
		<RhythmLyrics song={song} />
		<RhythmInfo name={name} />
	    </div>
	</div>
    );
}
