import React from 'react';
import { RhythmContext } from './RhythmContext.js';
import { v4 as uuidv4 } from 'uuid';

export function useUniqueID() {
    const [id, setID] = React.useState(0);
    
    React.useEffect(() => {
	setID(uuidv4());
    }, []);
    
    return `rhythm-comp-${id}`;
}

/**
 * Custom React Hook that preps for an animationRequest loop that
 * references the conductor logic.
 *
 * The callback is invoked with the current beat and previous beat.
 */
export const useConductedAnimationFrame = (callback, name, only_on=null) => {
    const { conductorRef } = React.useContext(RhythmContext);
    const requestRef = React.useRef();
    const previousTimeRef = React.useRef();

    // This is the render request function to be repeatedly invoked.
    const animate = time => {
	// Make sure to respect if we should stop animating.
	if (!conductorRef.current.isRunning()) {
	    return;
	}

	const [prevBeat, currBeat, crossBeat] = conductorRef.current.getFancyBeat(
	    previousTimeRef.current,
	    time,
	);

	// Either invoke a callback, or check that it is on the beat
	// that the callback is subscribed to.
	if (only_on === null || // Subscribed to everything animation frame
	    only_on === crossBeat || // Subscribed to specific beat
	    (only_on === true && crossBeat !== null) // Subscribed to all quantized beats.
	   ) {
	    callback(currBeat, prevBeat, crossBeat);
	}

	previousTimeRef.current = time;
	requestRef.current = requestAnimationFrame(animate);
    };

    // Instead of starting the animation right away (by requesting animation frame)
    // we return callbacks for start and end for application controller to
    // decide when to start / end.
    const start = React.useCallback(() => {
	//previousTimeRef.current = conductorRef.current.getStartTime();
	previousTimeRef.current = document.timeline.currentTime;
	requestRef.current = requestAnimationFrame(animate);
    }, []);
    const end = React.useCallback(() => {	
	if (requestRef.current) {
	    cancelAnimationFrame(requestRef.current);
	}
    }, []);

    React.useEffect(() => {
	if (!conductorRef.current) {
	    return;
	}
	conductorRef.current.addCallback([start, end], name);
    }, [conductorRef.current]);
    
    return [start, end];
}
