import * as Consts from './RhythmConsts.js';

export default class Conductor {
    bpm = 120; // beat per minute
    crotchet = 0; // sec/beat
    stderr = 0; // standard deviation of beat

    currentBar = 0;
    lastPrevQ = null; // used to track when we last bumped a bar
    timeOffset = null;
    
    // Quantize user beats
    quantizeRes = Consts.NOTE_8;
    
    granularity = Consts.NOTE_32;
    currentSong = null;
    
    startCallbacks = {};
    stopCallbacks = {};
    scoreCallbacks = {};
    songCallbacks = {};
    
    started = false;
    
    startTime = 0;
    startUnixTime = 0;

    answerBeats = {};

    offset = 0.1;
    
    constructor(bpm) {
	this.setBPM(bpm);
	this.currentSong = null;
    }

    getCurrentSong() {
	return this.currentSong;
    }

    loadSong(song) {
	this.perfects = 0;
	this.currentSong = song.precompute();
	this.setBPM(song.bpm);
	this.songEmit(song);
	this.scoreEmit(0, this.currentSong.perfects);
	return this;
    }
    
    /**
     * Takes a pair of callbacks, [startAnimation, stopAnimation].
     *
     * When conductor starts, startAnimation will be called,
     * when conductor stops, stopAnimation will be called.
     */
    addCallback(callback, name) {
	// console.log('adding callback for ' + name);
	this.startCallbacks[name] = callback[0];
	this.stopCallbacks[name] = callback[1];
	return this;
    }

    subscribeScoreChange(callback, name) {
	this.scoreCallbacks[name] = callback;
	return this;
    }

    subscribeSongChange(callback, name) {
	this.songCallbacks[name] = callback;
	return this;
    }
    
    setBPM(bpm) {
	// 60 sec/min / (bpm beat/min) = 60/bpm sec/beat
	this.bpm = bpm;
	this.bpms = bpm / 60000;
	this.crotchet = 60000 / this.bpm;
	this.stderr = this.crotchet >> 3; // 32nd note tolerance
	return this;
    }

    /**
     * Rount to the nearest quantized beat.
     */
    quantize(beat) {
	return Math.round(beat / this.quantizeRes) * this.quantizeRes;
    }
    
    initialize() {
	this.startTime = null;
	this.startUnixTime = null;
	this.currentBar = 0;
	this.lastPrevQ = null;
	
	this.started = false;
	this.answerBeats = {};
	this.timeOffset = null;
	return this;
    }

    onlineScore(score) {
	this.perfects += score;
	this.scoreEmit(score, this.perfects);
	return this;
    }
    
    scoreEmit(score, perfects) {
	for (const [k, callback] of Object.entries(this.scoreCallbacks)) {
	    callback(score, perfects);
	}
	return this;	
    } 

    songEmit(song) {
	for (const [k, callback] of Object.entries(this.songCallbacks)) {
	    callback(song);
	}
	return this;	
    }
   
    startAnimation() {
	for (const [k, callback] of Object.entries(this.startCallbacks)) {
	    callback();
	}
	return this;
    }

    stopAnimation() {
	for (const [k, callback] of Object.entries(this.stopCallbacks)) {
	    callback();
	}
	return this;
    }

    getStartTime() {
	if (!this.started) {
	    return null;
	}
	return this.startTime;
    }
    
    isRunning() {
	return this.started === true;
    }

    getBeat(t, bar) {
	return (t - this.startTime) * this.bpms;
    }

    getBeatUT(t, bar) {
	return (t - this.startUnixTime) * this.bpms;
    }

    /**
     * Input is the timestamp of the previous animation frame and the current
     * animation frame.
     *
     * In addition to returning the previous beat and current beat, we also compute,
     * to a specific granularity, the beat that was first entered.
     */
    getFancyBeat(pt, ct) {
	const pb = this.getBeat(pt, Consts.BAR);
	const cb = this.getBeat(ct, Consts.BAR);

	// Find the "beat" we just hit for the first time.
	const prevBeat = (pb % Consts.BAR); // / Consts.BAR;
	const currBeat = (cb % Consts.BAR);

	let prevQ = Math.floor(prevBeat / this.granularity);
	let currQ = Math.floor(currBeat / this.granularity);
	if (currQ === 0) {
	    currQ += Consts.BAR / this.granularity;
	}

	let crossBeat = null;
	// Detecting the first time a beat is hit
	if (prevQ + 1 === currQ) {
	    crossBeat = (currQ * this.granularity) % Consts.BAR;

	    // Calculate the time at the beginning of a bar
	    const barStartTime = Math.floor(pb) * this.crotchet + this.timeOffset;
	    // Also try to leverage this timer to increment bar
	    // but have to be careful with not double incrementing
	    if (crossBeat === 0 && barStartTime !== this.lastPrevQ) {
		//console.log('set bar time ' + barStartTime);
		this.lastPrevQ = barStartTime;
		this.currentBar += 1;
	    }
	}
	return [pb, cb, crossBeat];
    }

    beat() {
	if (!this.isRunning()) {
	    return;
	}
	// This is using unixtime for more global calibration
	const tt = (Date.now() - this.startUnixTime) * this.bpms
	let qt = this.quantize(tt);
	let qb = qt % Consts.BAR
	let bar = Math.floor(qt / Consts.BAR);

	// Store beats in user answer
	if (!(bar in this.answerBeats)) {
	    this.answerBeats[bar] = {};
	}
	if (!(qb in this.answerBeats[bar])) {
	    this.answerBeats[bar][qb] = 0;
	}
	this.answerBeats[bar][qb] += 1;
	
	// For reference, internally the game is tracked using this
	// const ss = this.getBeat(document.timeline.currentTime);
	if (!this.currentSong) {
	    return false;
	}
	const hasBeat = this.currentSong.hasBeat(qb);
	
	// const error = tt - qt;
	// console.log(hasBeat + '  ' + error);
	return hasBeat;
    }

    getGradableData() {
	let bar_time = this.startUnixTime + this.currentBar * this.crotchet * Consts.BAR;
	console.log('grab data ' + this.lastPrevQ);
	return {
	    'answer': this.getCurrentAnswer(),
	    'bar_time': bar_time,// this.lastPrevQ,
	    'bpm': this.bpm,
	}
    }

    getUpdatePayload() {
	return {
	    'name': this.currentSong.getName(),
	    'songid': this.currentSong.id,
	    'bpm': this.bpm,
	    'start_time': this.startUnixTime,
	};
    }
    
    getCurrentAnswer() {
	return this.currentSong.getAnswer(this.currentBar);
    }
    
    scoreAnswer(pb) {
	let bar = Math.floor(pb / Consts.BAR);
	//console.log(pb + '  scoring  ' + bar);
	let ans = bar in this.answerBeats ? this.answerBeats[bar] : {};
	// console.log(ans);
	const score = this.currentSong.scoreAnswer(bar, ans);

	this.scoreEmit(score, this.currentSong.perfects);

	// Some bullshit scoring metric for now
	if (Object.keys(ans).length === 0) {
	    return 0;
	}
	
	if (score === 1000) {
	    return 5;
	}
	
	return 1;
    }

    calibrateOffset(offset) {
	//console.log('old: ' + this.offset);
	this.offset = this.offsetRef + offset;
	//console.log('new: ' + this.offset);
	this.startTime = this.startTimeRef - this.offset;
	//console.log(this.startTime);
	//console.log(this.startTimeRef);
	
	return this;
    }
    
    startAt(ut0) {
	if (this.started) {
	    return null;
	}
	this.initialize();

	this.started = true;
	
	this.startTime = ut0;
	const currUnixTime = Date.now();
	const currHTMLTime = document.timeline.currentTime;
	this.timeOffset = currUnixTime - currHTMLTime;
	
	this.offsetRef = currUnixTime - ut0;
	this.offset = this.offsetRef;
	this.startUnixTime = ut0;
	// Calibrate start time for animation timer
	this.startTimeRef = document.timeline.currentTime;
	this.startTime = this.startTimeRef - this.offset;

	this.startAnimation();
	return this.startUnixTime;
    }
    
    start() {
	// Try to avoid double start
	if (this.started) {
	    return null;
	}

	this.initialize();
	
	this.started = true;
	this.startTimeRef = document.timeline.currentTime;	
	this.startTime = document.timeline.currentTime;
	this.startUnixTime = Date.now();
	this.timeOffset = this.startUnixTime - this.startTime;

	// console.log('start~~~~');
	this.startAnimation();
	
	return this.startUnixTime;
    }

    stop() {
	// console.log('stopped~~~~');
	this.stopAnimation();
	this.started = false;
    }
}
