import * as Consts from './RhythmConsts.js';

export class Beat {
    constructor(bar, beat, clue = '') {
	this.bar = bar;
	this.beat = beat;
	this.clue = clue;
    }
}

export class Song {
    constructor(bpm = 80) {
	this.id = 'song1';
	this.notes = [];
	this.bpm = bpm;
	// Used for storing beats that exist in the song as keys for fast lookup.
	this.beats = {};
	this.answer = {};
	this.perfects = 0;
	this.showAll = false;
	this.clue = '';
	this.clueClass = '';
	this.name = 'A Song';
	this.lyrics = [];
    }

    addLyrics(lyrics) {
	this.lyrics = lyrics;
	return this;
    }
    
    setName(name) {
	this.name = name;
	return this;
    }

    getName() {
	return this.name;
    }
    
    setClueClass(cls) {
	this.clueClass = cls;
	return this;
    }

    getClueClass() {
	return this.clueClass;
    }
    
    
    setClue(clue) {
	this.clue = clue;
	return this;
    }

    getClue() {
	return this.clue;
    }
    
    getBeatClue(bar, beat) {
	if (!(bar in this.beats)) {
	    return '';
	}
	if (!(beat in this.beats[bar])) {
	    return '';
	}
	return this.beats[bar][beat].clue;
    }
    
    /**
     * For puzzle beats, instead of showing only the correct beats,
     * we show all of them and clue the answer indirectly.
     */
    setShouldForceShowAll() {
	this.showAll = true;
	return this;
    }
    
    resetState() {
	// PROBABLY SHOULD MOVE STATE OUT OF THIS.
	this.perfects = 0;
	return this;
    }

    scoreAnswer(bar, answer) {
	let total = 0;
	let correct = 0;
	for (let b = 0; b < Consts.BAR; b += Consts.NOTE_16) {
	    let should = (bar in this.beats) && (b in this.beats[bar]);
	    let actual = b in answer;
	    total += 1;
	    if (!(should ^ actual)) {
		correct += 1;
	    }
	}
	
	// for (const [k, v] of Object.entries(answer))
	let score = Math.round(correct / total * 1000);
	if (correct === total) {
	    // this.perfects += 1;
	}
	return score;
    }
    
    setAnswers(answer) {
	this.answer = answer;
	return this;
    }
    
    hasBeat(bar, beat) {
	return (bar in this.beats) && (beat in this.beats[bar]);
    }

    shouldShow(bar, beat) {
	return this.showAll || this.hasBeat(bar, beat);
    }
    
    getBPM() {
	return this.bpm;
    }
    
    add(beat) {
	this.notes.push(beat);
	// Add a new bar if first time.
	if (!(beat.bar in this.beats)) {
	    this.beats[beat.bar] = {};
	}
	// Add the beat to the bar
	this.beats[beat.bar][beat.beat] = beat;
	return this;
    }

    sort() {
	this.notes.sort((a, b) => a.position - b.position);
    }

    getAnswer(bar) {
	// TODO respect bar #
	return this.answer;
    }
    
    precompute() {
	//for (const beat of this.notes) {
	//    this.beats[beat.beat] = beat;
	//}
	// console.log(this.beats);
	return this;
    }
}

export class TransitionSong extends Song {
    constructor() {
	super();
	this.perfects = Consts.GET_READY;
    }

    /**
     * There's nothing going on during transition, and we just
     * return a custom value to indicate "next level" instead of
     * trying to say anything else while nothing is going on.
     */
    scoreAnswer(bar, answer) {
	if (this.perfects === Consts.NEXT_LEVEL) {
	    this.perfects = Consts.GET_READY;
	} else if (this.perfects === Consts.GET_READY) {
	    this.perfects = Consts.NEXT_LEVEL;
	}
	return this.perfects;
    }
    
    resetState() {
	this.perfects = Consts.NEXT_LEVEL;
	return this;
    }

    hasBeat(beat) {
	return false;
    }
}
