import './RhythmMeter.css';

import React from 'react';

import * as Consts from './RhythmConsts.js';
import { RhythmContext } from './RhythmContext.js';

export default function RhythmMeter() {
    const { conductorRef } = React.useContext(RhythmContext);
    const [score, setScore] = React.useState(0);
    const [increment, setIncrement] = React.useState();
    
    const onScoreChange = React.useCallback((score, perfects) => {
	setIncrement(score);
	setScore(perfects);
    }, []);
    
    React.useEffect(() => {
	if (!conductorRef.current) {
	    return;
	}
	conductorRef.current.subscribeScoreChange(onScoreChange, 'meter');
    }, [conductorRef.current]);

    const currScore= Math.min(score, Consts.GOAL);
    const style = {
	"--score-cur": currScore,
	"--score-max": Consts.GOAL,
    };

    const maxed = currScore === Consts.GOAL;
    return (
	<div className={"gameMeter " + (maxed ? 'maxedMeter' : '')}>
	    <div className="meterFrame">
		<div className="meterInnerBottom" style={style} />
		<div className="meterInner" style={style} />
	    </div>
	</div>
    );
}

