import React from 'react';
import Conductor from './Conductor.js';
import { s, s2 } from './Songs.js';

/**
 * Exposes any state that's shared across all components in the game.
 *
 * Most importantly is the Conductor which keeps track of the game
 * state (responsible for keeping time).
 */
const RhythmContext = React.createContext();

const RhythmProvider = ({ children }) => {
    const conductorRef = React.useRef(null);

    // Initialize the conductor one time
    React.useEffect(() => {
	if (conductorRef.current === null) {
	    conductorRef.current = new Conductor(120, 60);
	    conductorRef.current.loadSong(s);
	}
    }, []);
    
    return (
	<RhythmContext.Provider value={{ conductorRef }}>
	    {children}
	</RhythmContext.Provider>
    );
};

export { RhythmContext, RhythmProvider };
