import React from 'react';
import { useCallback, useState, useEffect, useRef } from 'react'; 
import { Button, Card, CardGroup, Col, Row } from 'react-bootstrap';
import {Routes, Route, useSearchParams} from 'react-router-dom';
import './Random.css';


function randrange(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export default function EventDetail({eventID, showForm=false}) {
    const [isRunning, setIsRunning] = React.useState(false);
    const [num, setNum] = React.useState(42);

    let [searchParams] = useSearchParams();
    let a = Number(searchParams.get('a'));
    let b = Number(searchParams.get('b'));

    if (a == 0 & b == 0) {
        b = 100;
    }
    
    const handleUserKeyPress = useCallback(event => {
        const { key, keyCode } = event;
        if (keyCode === 64 + 18) {
            setIsRunning(!isRunning)
        }
    }, [isRunning]);

    useEffect(() => {
        window.addEventListener("keydown", handleUserKeyPress);
        return () => {
            window.removeEventListener("keydown", handleUserKeyPress);
        };
    }, [handleUserKeyPress]);    
    
    useEffect(() => {
        if (!isRunning) {
            return;
        }
        
        const intervalID = setInterval(() => {
            setNum(randrange(a, b));
        }, 100);

        return () => clearInterval(intervalID); // Cleanup
    }, [isRunning]);

    
    return (
        <div className="random-outer-container">
            <div className="random-container">
                <div className="centered-element">
                    <div className={isRunning ? '' : 'growdiv'}>
                        {num}
                    </div>
                </div>
                <div className="randomLogo logo-chestnuters">
                    <img src="/static/chestnuters.png" height="250" />
                </div>
                <div className="randomLogo logo-palefire">
                    <img src="/static/palefire.jpeg" height="250" />
                </div>
                <div className="randomLogo logo-flatdog">
                    <img src="/static/flatdog_logo.png" height="300" />
                </div>
            </div>
        </div>
    );
}
