export const ORBIT_R = 350;
export const BPMS = 0.0008;
export const BAR = 4;
export const epsilon = 0.0001;
export const GOAL = 2000;

export const NEXT_LEVEL = 9999;
export const GET_READY = 9998;

export const NOTE_1 = 4;
export const NOTE_2 = 2;
export const NOTE_4 = 1;
export const NOTE_8 = 0.5;
export const NOTE_16 = 0.25;
export const NOTE_32 = 0.125;
