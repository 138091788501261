import './RhythmClient.css';
import './RhythmBackground.css';

import React from 'react';

import { useWebSocket } from './RhythmSocket.js';
import { RhythmClientBackground } from './RhythmBackground.js';
import { RhythmFeedback, RhythmInfo } from './RhythmFeedback.js';
import * as Consts from './RhythmConsts.js';
import { RhythmContext } from './RhythmContext.js';
import { useConductedAnimationFrame, useUniqueID } from './RhythmHook.js';
import { songlist } from './Songs.js';

export default function RhythmClient() {
    const componentId = useUniqueID(); // React.useId();
    const { conductorRef } = React.useContext(RhythmContext);

    const [pulse, setPulse] = React.useState(0);
    const [connected, setConnected] = React.useState(false);
    const [band, setBand] = React.useState();
    const [name, setName] = React.useState();
    const [bpm, setBPM] = React.useState();
    const [startTime, setStartTime] = React.useState();
    const [song, setSong] = React.useState();
    const [started, setStarted] = React.useState(false);
    const [answer, setAnswer] = React.useState([]);
    const [lastBeat, setLastBeat] = React.useState(0);

    const [requestUpdateTime, setRequestUpdateTime] = React.useState();

    const openCallback = (e) => {
	setConnected(true);
    };
    
    const messageCallback = (e) => {
	let conductor = conductorRef.current;
        const data = JSON.parse(e.data);
	
        switch (data.type) {
	case 'calibrate':
	    if (!('time' in data)) {
		break;
	    }

	    let host_time = data['time'];
	    // host_time + round_trip + offset = client_time
	    let offset = Date.now() - host_time - conductor.roundTrip;
	    //conductor.calibrateOffset(offset);
	    //console.log('round : ' + conductor.roundTrip);
	    //console.log(host_time);
	    //console.log(Date.now());
	    break;
        case 'client_update':
	    if ('band' in data) {
		setBand(data.band);
	    }

	    if ('name' in data) {
		setName(data.name);
	    }

	    if ('start_time' in data) {
		setStartTime(data.start_time);
	    }

	    if ('bpm' in data) {
		setBPM(data.bpm);
	    }

	    if ('started' in data) {
		setStarted(data.started);
	    }

	    if ('songid' in data) {
		setSong(data.songid);
		if (song !== data.songid && data.songid in songlist) {
		    conductorRef.current.loadSong(songlist[data.songid]);
		}
	    }

	    // We can use this information to calibrate ping
	    if ('req_time' in data) {
		const curr_time = Date.now();
		const req_time = data.req_time;

		let rt = curr_time - req_time;
		conductor.roundTrip = rt;
		sendMessage({
		    roundtrip: rt,
		});
	    }
	    
            break;
	default:
	    break;
	}	
    };
    
    const closeCallback = (e) => {
	setConnected(false);
    };

    const sendMessage = useWebSocket(
	openCallback,
	messageCallback,
	closeCallback,
    );

    useConductedAnimationFrame((cb, pb) => {
	let conductor = conductorRef.current;
	// websocket submit answer calls this
	let score = conductor.scoreAnswer(pb);
	sendMessage({
	    type: 'answer',
	    score: score,
	    other: conductor.answerBeats,
	});
    }, componentId, Consts.BAR - Consts.NOTE_16);
    
    // Executes logic when a new connection is established.
    React.useEffect(() => {
	if (!connected) {
	    return;
	}

	// Requests for perfoermance state update
	const request_time = Date.now();
	setRequestUpdateTime(request_time);
	sendMessage({
	    type: 'request_update',
	    req_time: request_time,
	});
    }, [connected]);
    
    const handleBeat = React.useCallback((e) => {
	if (!connected) {
	    return;
	}
	let conductor = conductorRef.current;

	let beat = Date.now();
	if ((beat - lastBeat) < (conductor.crotchet * Consts.NOTE_32)) {
	    return;
	}

	/*
	sendMessage({
	    t: conductor.startUnixTime,
	    ct: beat,
	    ht: conductor.startTime,
	    cht: document.timeline.currentTime,
	    off: conductor.offset,
	    off2: conductor.timeOffset,
	});
	*/
	setLastBeat(beat);
	setPulse(prevPulse => (prevPulse + 1) % 2);

	conductor.beat();
	setAnswer(prevAns => {
	    prevAns.push(beat);
	    return prevAns;
	});
	// sendMessage({type: 'beat', t: Date.now()});
    }, [connected, lastBeat]);


    React.useEffect(() => {
	let conductor = conductorRef.current;
	// Don't do anything if conductor is not here
	if (!conductor) {
	    return;
	}

	if (started) {
	    conductor.setBPM(bpm).startAt(startTime);
	} else {
	    conductor.stop();
	}
    }, [started, bpm, startTime])
    
    const handleKeyDown = React.useCallback((event) => {
	switch (event.key) {
	case ' ':
	    handleBeat(event);
	    return;
	default:
	    return;
	}
    }, [handleBeat]);
    
    // Register keylistener
    React.useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            // unsubscribe event
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleKeyDown]);

    
    return (
	<div className="rhythmClientScreen">
	    <div
		className="overlayButton"
		onTouchStart={handleBeat}
	    />
    	    <div
		className="rhythmButton">
		<span key={"pulse" + pulse} className="buttonPulse" />
	    </div>
	    <RhythmFeedback
		scoringFn={(score) => {
		    return '';
		    //if (score === 1000) return 'PERFECT!';
		    //return 'Keep it Up!';
		}}
	    />
	    <RhythmInfo name={name} />
	    <RhythmClientBackground key={"pulse" + pulse} name={band} />
	</div>
    );
}
