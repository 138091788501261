import React from 'react';

/**
 * Custom React Hook that establishes a websocket connection.
 */
export const useWebSocket = (
    openCallback,
    messageCallback,
    closeCallback,
) => {
    const sockRef = React.useRef(null);
    const sendWS = (payload) => {
        sockRef.current.send(JSON.stringify(payload));
    };

    React.useEffect(() => {
        // wss in production and ws in development
        let sock = new WebSocket('wss://' + window.location.host + '/ws/rhythm/test/');
	// let sock = new WebSocket('ws://localhost:8000/ws/rhythm/test/');
	// let sock = new WebSocket('ws://192.168.1.8:8000/ws/rhythm/test/');
        sockRef.current = sock;
        sock.onmessage = (e) => {
	    messageCallback(e);
        };
        sock.onclose = (e) => {
            console.log('You are disconnected from the server');
            closeCallback(e);
        };
        sock.addEventListener('open', (e) => {
            console.log('connected');
	    openCallback(e);
        });
        return () => {
            console.log('Disconnecting...');
            sock.close();
        };
    }, []);
    
    return sendWS;
}
