import { Beat, Song, TransitionSong } from './RhythmSong.js';
import * as Consts from './RhythmConsts.js';

function randRange(a, b) {
    return Math.floor(Math.random() * (b - a + 1)) + a;
}

function shuffle(array) {
    let currentIndex = array.length;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
	
	// Pick a remaining element...
	let randomIndex = Math.floor(Math.random() * currentIndex);
	currentIndex--;

	// And swap it with the current element.
	[array[currentIndex], array[randomIndex]] = [
	    array[randomIndex], array[currentIndex]];
    }
    return array;
}

function addAllEighth(song, clue=null) {
    let beat = 0;
    while (beat < Consts.BAR) {
	song.add(new Beat(1, beat, clue))
	beat += Consts.NOTE_8;
    }
}

export default class RhythmGenerator {
    /**
     * Only quarter notes, and always contains first beat.
     */
    static genSimple1() {
	let song = new Song();
	let r = randRange(0, 7) * 2 + 1;

	let beat = 0;
	let ans = [];
	while (r > 0) {
	    if (r % 2 === 1) {
		song.add(new Beat(1, beat))
		ans.push(beat);
	    }
	    r >>= 1;
	    beat += 1;
	}
	song.setAnswers(ans.sort());
	return song;
    }

    /**
     * Generates 4 eighth notes.
     */
    static genSimple2() {
	let song = new Song();
	let beats = [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5];
	shuffle(beats);
	let ans = [];
	for (let b = 0; b < 4; b++) {
	    let beat = beats[b];
	    song.add(new Beat(1, beat))
	    ans.push(beat);
	}
	song.setAnswers(ans.sort());
	return song;
    }

    /**
     * Puzzle: Clock.
     * Prompt: A time is given.
     * Solution: The direction of the hour and minute hand point at the active beats.
     */
    static genClockPuzzle() {
	let song = new Song();
	const bank = {
	    '3:00': [0, 1],
	    '6:00': [0, 2],
	    '9:00': [0, 3],
	    '12:00': [0],
	    '1:30' : [2, 0.5],
	    '4:30' : [2, 1.5],
	    '7:30' : [2, 2.5],
	    '10:30' : [2, 3.5],
	};
	let bankList = Object.entries(bank);
	let problem = bankList[randRange(0, bankList.length - 1)];

	
	
	addAllEighth(song, '?');
	let ans = [];
	for (const beat of problem[1]) {
	    ans.push(beat);
	}
	return song
	    .setAnswers(ans.sort())
	    .setClue(problem[0] + (randRange(0, 1) ? 'pm' : 'am'));
    }

    /**
     * Puzzle: Letter Pointer.
     * Prompt: A letter is given.
     * Solution: The points around the circumference point at the active beats
     */
    static genLetterPuzzle() {
	let song = new Song();
	const bank = {
	    '\uFF0A': [0, 0.5, 1.5, 2, 2.5, 3.5], // *
	    '\uFF0D': [1, 3], // -
	    
	    '\uFF21': [0, 1.5, 2.5], 
	    '\uFF29': [0, 2],
	    '\uFF2E': [0.5, 1.5, 2.5, 3.5],
	    '\uFF34': [0.5, 2, 3.5],
	    '\uFF36': [0.5, 2, 3.5],
	    '\uFF38': [0.5, 1.5, 2.5, 3.5],
	    '\uFF39': [0.5, 2, 3.5],
	    '\uFF3A': [0.5, 1.5, 2.5, 3.5],
	    
	    '\uFF0F': [0.5, 2.5], // /
	    '\uFF3C': [1.5, 3.5], // \
	    
	};
	let bankList = Object.entries(bank);
	let problem = bankList[randRange(0, bankList.length - 1)];
	
	addAllEighth(song, '?');
	let ans = [];
	for (const beat of problem[1]) {
	    ans.push(beat);
	}
	return song
	    .setAnswers(ans.sort())
	    .setClueClass('giantClue')
	    .setClue(problem[0]);
    }

    static transition() {
	return new TransitionSong();
    }
}
